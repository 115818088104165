<template>
    <v-footer fixed dark padless>
        <v-row class="ma-0 text-center">
            <v-col class="px-0">
                <v-btn class="pa-0" block to="/"><v-icon size="36px">mdi-map-marker</v-icon></v-btn>
            </v-col>
            <v-col class="px-0">
                <v-btn class="pa-0" block to="/device-list"><v-icon size="36px">mdi-view-headline</v-icon></v-btn>
            </v-col>
            <v-col class="px-0">
                <v-btn class="pa-0" block to="/settings"><v-icon size="36px">mdi-cog</v-icon></v-btn>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: "CommonFooter",
    data: () => ({}),
};
</script>
