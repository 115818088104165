<template>
    <v-container>
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================================================================================================ -->
        <v-row>
            <v-col>
                <h2 class="my-2">デバイス一覧</h2>
            </v-col>
        </v-row>
        <!-- ============================================================================================================================ -->
        <v-row>
            <v-col cols="12" md="6" lg="4" v-for="obj of dbDevices" :key="obj.id">
                <v-card>
                    <v-toolbar color="indigo" dark>
                        <v-toolbar-title class="font-weight-bold">{{ obj.name }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <h4>アイコン：{{ obj.icon_no == 0 ? "中" : obj.icon_no }}</h4>
                        <h4>軌跡カラー：<v-progress-linear value="100" height="7" :color="obj.poly_color"></v-progress-linear></h4>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small color="success" @click="openDlgClick(obj.id)"><h3>編集</h3></v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <!-- ============================================================================================================================ -->
        <v-dialog v-model="showEditDlg" fullscreen transition="dialog-bottom-transition">
            <v-card color="grey lighten-4">
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>{{ dlgDevice.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="showEditDlg = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text class="mt-10">
                    <v-row>
                        <v-col>
                            <v-sheet class="pa-3" elevation="2">
                                <v-container>
                                    <v-row>
                                        <v-col cols="8">
                                            <v-text-field label="デバイス名" v-model="dlgDevice.name" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-select label="アイコン" v-model="dlgDevice.icon_no" outlined dense :items="optMapIconNo" readonly background-color="grey lighten-3"></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-menu v-model="dispColorDlg" :close-on-content-click="false" transition="scale-transition" min-width="auto" :nudge-bottom="40">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field label="軌跡カラー" v-model="dlgDevice.poly_color" readonly v-bind="attrs" v-on="on" dense outlined style="width: 120px"></v-text-field>
                                        </template>
                                        <v-color-picker v-model="dlgDevice.poly_color" mode="hexa" show-swatches swatches-max-height="400px" hide-canvas hide-inputs hide-sliders></v-color-picker>
                                    </v-menu>
                                </v-container>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- --------------------- -->
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <v-btn color="success" @click="showEditDlg = false" width="100" outlined>キャンセル</v-btn>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <v-btn color="success" @click="axiosSubmit()" width="100">登録</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- --------------------- -->
            </v-card>
        </v-dialog>
        <!-- ============================================================================================================================ -->
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
    </v-container>
</template>

<script>
export default {
    name: "Devices",
    data() {
        return {
            jwt: "",
            myInfo: { id: 0, name: "", user_id: 0, site_id: 0 },
            showEditDlg: false,
            isLoading: false,
            dbDevices: [],
            dlgInfo: {
                ipv4: "",
                local_ip: "",
                sd_ver: "",
            },
            dlgDevice: {
                id: 0,
                site_id: 0,
                mac: "ff:ff:ff:ff:ff:ff",
                name: "Newイケス",
                sort_no: 999,
            },
            optMapIconNo: [
                { value: 1, text: "1" },
                { value: 2, text: "2" },
                { value: 3, text: "3" },
                { value: 4, text: "4" },
                { value: 5, text: "5" },
                { value: 6, text: "6" },
                { value: 7, text: "7" },
                { value: 8, text: "8" },
                { value: 9, text: "9" },
                { value: 0, text: "中継機" },
            ],
            dispColorDlg: false,
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //-----------------------------------------------
        this.isLoading = true;
        //-----------------------------------------------
        //非同期でAxiosGet
        const promiseDevice = this.axiosGetDevices();
        //-----------------------
        //上記の終了を待つ
        await promiseDevice;
        //-----------------------
        await new Promise((r) => setTimeout(r, 500));
        //-----------------------
        this.isLoading = false;
        window.scrollTo(0, 0);
        //-----------------------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    methods: {
        //===================================================
        async axiosGetDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //===================================================
        // 変更ダイアログ表示
        //===================================================
        openDlgClick(primaryKey) {
            //-----------------------------
            if (primaryKey) {
                for (let obj of this.dbDevices) {
                    if (obj.id == primaryKey) {
                        this.dlgDevice = JSON.parse(JSON.stringify(obj));
                    }
                }
            } else {
                this.dlgDevice["id"] = 0;
                this.dlgDevice["name"] = "新規イケス";
                this.dlgDevice["sort_no"] = "1";
                this.dlgDevice["mac"] = "xx:xx:xx:xx:xx:xx";

                this.dlgDevice["feeder_id"] = 0;
                this.dlgDevice["sensor_id"] = 0;
                this.dlgDevice["camera1_id"] = 0;
                this.dlgDevice["camera2_id"] = 0;
                this.dlgDevice["camera3_id"] = 0;
                this.dlgDevice["camera4_id"] = 0;

                delete this.dlgDevice["sd_ver"];
                delete this.dlgDevice["alived_at"];
                delete this.dlgDevice["modified"];
            }
            this.showEditDlg = true;
        },
        //===================================================
        // 給餌プランDataをサブミット
        //===================================================
        async axiosSubmit() {
            let ErrCount = 0;
            if (!this.dlgDevice["name"]) ErrCount++;
            //if (!this.dlgDevice["icon_no"]) ErrCount++;
            if (!this.dlgDevice["poly_color"]) ErrCount++;
            if (ErrCount) {
                alert("未入力項目があります。");
                return false;
            }
            //-----------------------------------
            this.isLoading = true;
            this.showEditDlg = false;
            //-----------------------------------
            const axiosData = {
                name: this.dlgDevice["name"],
                icon_no: this.dlgDevice["icon_no"],
                poly_color: this.dlgDevice["poly_color"],
            };
            //-----------------------------------
            const primaryKey = this.dlgDevice.id;
            await this.axios({
                method: "PUT",
                url: "/web/api/devices/" + primaryKey,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000,
            }).then(() => {
                this.axiosGetDevices();
            });
            //-----------------------------------
            await new Promise((r) => setTimeout(r, 100));
            this.isLoading = false;
        },
        //===================================================
    },
    //========================================================
};
</script>
