<template>
    <v-container>
        <v-overlay :value="isLoading" absolute>
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ============================================================================================================================ -->
        <v-row>
            <v-col>
                <h2 class="ma-3">パトランプ一覧</h2>
            </v-col>
        </v-row>
        <!-- ============================================================================================================================ -->
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="mx-2"></v-text-field>
        <v-data-table hide-default-footer :headers="tableHeaders" :items="dbPatoLamps" mobile-breakpoint="0" :items-per-page="300" :search="search" class="elevation-5 ma-2">
            <template v-slot:[`item.name`]="{ item }">
                <v-btn @click="openDlgClick(item.id)" text color="blue">{{ item.name }}</v-btn>
            </template>
            <template v-slot:[`item.id`]="{ item }">
                <v-btn @click="mqttPublishPatoSW(item.mac, 'on', 'red')" class="ma-1" color="red" :disabled="$moment().add(-1, 'minutes') > $moment(item.alived_at)">赤</v-btn>
                <v-btn @click="mqttPublishPatoSW(item.mac, 'on', 'yellow')" class="ma-1" color="yellow" :disabled="$moment().add(-1, 'minutes') > $moment(item.alived_at)">黄</v-btn>
                <v-btn @click="mqttPublishPatoSW(item.mac, 'on', 'green')" class="ma-1" color="green" :disabled="$moment().add(-1, 'minutes') > $moment(item.alived_at)">緑</v-btn>
                <v-btn @click="mqttPublishPatoSW(item.mac, 'on', 'buzzer')" class="ma-1" color="purple" :disabled="$moment().add(-1, 'minutes') > $moment(item.alived_at)">ブザー</v-btn>
                <v-btn @click="mqttPublishPatoSW(item.mac, 'off', '')" class="ma-1" color="white" :disabled="$moment().add(-1, 'minutes') > $moment(item.alived_at)">停止</v-btn>
            </template>
            <template v-slot:[`item.alived_at`]="{ item }">
                <div>{{ $moment(item.alived_at).format("YYYY-MM-DD HH:mm") }}</div>
            </template>
        </v-data-table>
        <!-- ============================================================================================================================ -->
        <v-dialog v-model="showEditDlg" fullscreen transition="dialog-bottom-transition">
            <v-card color="grey lighten-4">
                <v-toolbar color="indigo" dark>
                    <v-toolbar-title>{{ dlgPatoLamp.name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="showEditDlg = false"><v-icon>mdi-close</v-icon></v-btn>
                </v-toolbar>
                <v-card-text class="mt-10">
                    <v-row>
                        <v-col>
                            <v-sheet class="pa-3" elevation="2">
                                <v-container>
                                    <v-row>
                                        <v-col cols="4">
                                            <v-text-field label="管理番号" v-model="dlgPatoLamp.sort_no" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="8">
                                            <v-text-field label="パトランプ名" v-model="dlgPatoLamp.name" outlined dense></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field label="Macアドレス" v-model="dlgPatoLamp.mac" outlined dense disabled></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- --------------------- -->
                <v-card-text class="mt-5">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <v-btn color="success" @click="showEditDlg = false" width="100" outlined>キャンセル</v-btn>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <v-btn color="success" @click="axiosSubmit()" width="100">登録</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
                <!-- --------------------- -->
            </v-card>
        </v-dialog>
        <!-- ============================================================================================================================ -->
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
    </v-container>
</template>

<script>
import Config from "@/config.json";
import mqtt from "mqtt";
export default {
    name: "Sites",
    data() {
        return {
            jwt: "",
            myInfo: { id: 0, name: "", user_id: 0, site_id: 0 },
            showEditDlg: false,
            isLoading: false,
            dbSites: [],
            dbPatoLamps: [],
            dlgPatoLamp: {
                id: 0,
                site_id: 0,
                sort_no: 100,
                name: "",
                mac: "",
            },
            search: "",
            tableHeaders: [
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 80, text: "管理番号", value: "sort_no" },
                // { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 100, text: "都道府県", value: "pref" },
                // { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 100, text: "市町村", value: "city" },
                // { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 180, text: "組織名", value: "site_name" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 180, text: "パトランプ名", value: "name" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 150, text: "Macアドレス", value: "mac" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 150, text: "アライブ", value: "alived_at" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 430, text: "動作テスト", value: "id" },
            ],
            optSites: [],
            //-----------------------------
            mqttHost: Config.mqttHost,
            mqttID: Config.mqttID,
            mqttPW: Config.mqttPW,
            mqttTopicPub: Config.mqttTopicPub,
            mqttTopicSub: Config.mqttTopicSub,
            mqttClient: { connected: false },
            //-----------------------------
        };
    },
    //========================================================
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return;
        }
        //-----------------------------------------------
        this.isLoading = true;
        //-----------------------------------------------
        //非同期でAxiosGet
        await this.axiosGetSites();
        await this.axiosGetPatoLamps();
        //-----------------------
        await new Promise((r) => setTimeout(r, 500));
        //-----------------------
        this.connectMqtt();
        this.isLoading = false;
        window.scrollTo(0, 0);
        //-----------------------------------------------
    },
    //========================================================
    beforeDestroy: async function () {
        this.disconnectMqtt();
    },
    //========================================================
    methods: {
        //===================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
                this.optSites = [];
                for (let obj of this.dbSites) {
                    this.optSites.push({ value: obj.id, text: obj.pref + " " + obj.city + " " + obj.name });
                }
            });
        },
        //===================================================
        async axiosGetPatoLamps() {
            await this.axios({
                method: "GET",
                url: "/web/api/pato_lamps",
                params: { "_order[sort_no]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbPatoLamps = response.data.json;
                for (let obj of this.dbPatoLamps) {
                    obj["site_name"] = this.siteId2Name(obj.site_id);
                    obj["pref"] = this.siteId2Pref(obj.site_id);
                    obj["city"] = this.siteId2City(obj.site_id);
                }
            });
        },
        //===================================================
        siteId2Name(site_id) {
            let name = "";
            for (let obj of this.dbSites) if (obj.id == site_id) name = obj.name;
            return name;
        },
        siteId2Pref(site_id) {
            let name = "";
            for (let obj of this.dbSites) if (obj.id == site_id) name = obj.pref_id + ":" + obj.pref;
            return name;
        },
        siteId2City(site_id) {
            let name = "";
            for (let obj of this.dbSites) if (obj.id == site_id) name = obj.city;
            return name;
        },
        //===================================================
        // 変更ダイアログ表示
        //===================================================
        openDlgClick(primaryKey) {
            //-----------------------------
            if (primaryKey) {
                for (let obj of this.dbPatoLamps) {
                    if (obj.id == primaryKey) {
                        this.dlgPatoLamp = JSON.parse(JSON.stringify(obj));
                    }
                }
            } else {
                this.dlgPatoLamp["id"] = 0;
                this.dlgPatoLamp["site_id"] = 0;
                this.dlgPatoLamp["sort_no"] = 100;
                this.dlgPatoLamp["name"] = "";
                this.dlgPatoLamp["mac"] = "";
                delete this.dlgPatoLamp["modified_at"];
            }
            this.showEditDlg = true;
        },
        //===================================================
        // 給餌プランDataをサブミット
        //===================================================
        async axiosSubmit() {
            let ErrCount = 0;
            if (!this.dlgPatoLamp["site_id"]) ErrCount++;
            if (!this.dlgPatoLamp["sort_no"]) ErrCount++;
            if (!this.dlgPatoLamp["name"]) ErrCount++;
            if (!this.dlgPatoLamp["mac"]) ErrCount++;
            if (ErrCount) {
                alert("未入力項目があります。");
                return false;
            }
            //-----------------------------------
            this.isLoading = true;
            this.showEditDlg = false;
            //-----------------------------------
            // const axiosData = JSON.parse(JSON.stringify(this.dlgPatoLamp));
            // delete axiosData["modified"];
            //-----------------------------------
            let axiosData = {
                id: this.dlgPatoLamp["id"],
                site_id: this.dlgPatoLamp["site_id"],
                sort_no: this.dlgPatoLamp["sort_no"],
                name: this.dlgPatoLamp["name"],
                mac: this.dlgPatoLamp["mac"],
            };
            //-----------------------------------
            const primaryKey = this.dlgPatoLamp.id;
            let restMethod = "PUT";
            if (primaryKey == 0) restMethod = "POST";

            let restUrl = "/web/api/pato_lamps";
            if (primaryKey > 0) restUrl = restUrl + "/" + primaryKey;

            await this.axios({
                method: restMethod,
                url: restUrl,
                headers: { Authorization: "Bearer " + this.jwt },
                data: axiosData,
                timeout: 30000,
            })
                .then(() => {
                    this.axiosGetPatoLamps();
                })
                .catch(function (error) {
                    if (error.response.data.err == "ER_DUP_ENTRY") {
                        alert("重複しています。\n保存できません。");
                    } else {
                        alert("ん？ " + error.response.data.err);
                    }
                });
            //-----------------------------------
            await new Promise((r) => setTimeout(r, 100));
            this.isLoading = false;
        },
        //===================================================
        // MQTT系
        //===================================================
        async connectMqtt() {
            //-----------------
            if (this.mqttClient.connected) return true;
            //-----------------
            this.mqttClient = mqtt.connect(
                this.mqttHost
                //{ username: this.mqttID, password: this.mqttPW }
            );
            //-----------------
            this.mqttClient.on("connect", function () {
                self.console.log("MQTT Connected !!");
            });
            //-----------------
        },
        //====================================================
        disconnectMqtt() {
            if (!this.mqttClient.connected) return true;
            this.mqttClient.end();
        },
        //====================================================
        mqttPublishPatoSW(mac, sw, vals) {
            const topic = this.mqttTopicPub + "/" + mac;
            const pubMessage = {
                pato: sw,
                vals: vals,
            };
            if (this.mqttClient.connected) {
                this.mqttClient.publish(topic, JSON.stringify(pubMessage));
                // self.console.log({ topic, pubMessage });
            }
        },
        //====================================================
    },
    //========================================================
};
</script>
