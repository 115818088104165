import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";
import Map from "../views/Map.vue";
import Settings from "../views/Settings.vue";
import DeviceList from "../views/DeviceList.vue";
import Devices from "../views/Devices.vue";
import PatoLamps from "../views/PatoLamps.vue";

Vue.use(VueRouter);

const routes = [
    {
        name: "Login",
        path: "/login",
        meta: { isPublic: true },
        component: Login,
    },
    {
        name: "Logout",
        path: "/logout",
        meta: { isPublic: true },
        component: Logout,
    },
    {
        path: "/",
        name: "Map",
        component: Map,
    },
    {
        name: "DeviceList",
        path: "/device-list",
        component: DeviceList,
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
    },
    {
        name: "Devices",
        path: "/devices",
        component: Devices,
    },
    {
        name: "PatoLamps",
        path: "/pato-lamps",
        component: PatoLamps,
    },
];

const router = new VueRouter({
    routes,
});

export default router;
