<template>
    <v-container fluid class="pa-0">
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ========================================================= -->
        <l-map ref="map" :style="mapCSS()" :zoom="zoom" :minZoom="6" :center="[center.lat, center.lng]" @baselayerchange="baselayerChanged" @update:zoom="zoomUpdated" @update:center="centerUpdated" :options="{ zoomControl: false }">
            <!-- ================ -->
            <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :options="tileProvider.options" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
            <!-- ================ -->
            <!-- ===== カレンダー ===== -->
            <l-control position="topleft">
                <v-menu v-model="dispMonthDlg" :close-on-content-click="false" transition="scale-transition" min-width="auto" :nudge-bottom="40">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="targetDate" readonly v-bind="attrs" v-on="on" dense outlined background-color="grey lighten-4" style="width: 120px"></v-text-field>
                    </template>
                    <v-date-picker v-model="targetDate" locale="jp" :max="today" @input="reloadDeviceLogs()"></v-date-picker>
                </v-menu>
            </l-control>
            <!-- ================ -->
            <l-control position="topright">
                <v-btn elevation="3" @click.stop="drawer = !drawer">
                    <v-icon color="grey darken-3">mdi-cog</v-icon>
                </v-btn>
                <div v-show="false">{{ windowHeight }},{{ windowWidth }}</div>
            </l-control>
            <!-- ================ -->
            <l-control-layers position="bottomright"></l-control-layers>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <!-- ================ -->
            <!-- ===== スライダー ===== -->
            <l-control position="bottomleft">
                <div class="mx-9" style="width: 70vw">
                    <v-slider v-model="sliderVal" min="0" max="1440" step="5" @input="markerRegist()" hide-details color="green darken-1" track-color="green lighten-2" thumb-label="always" thumb-size="70">
                        <template v-slot:thumb-label="{ value }">
                            <h2>{{ Math.floor(value / 60) }}:{{ ("00" + (value % 60)).slice(-2) }}</h2>
                        </template>
                    </v-slider>
                </div>
            </l-control>
            <!-- ================ -->
            <!-- ===== 親機 ===== -->
            <l-marker v-for="(p, idx) in trackingGateway" :key="'GW-' + idx" :lat-lng="p.marker.latlng" :zIndexOffset="100">
                <l-icon :icon-anchor="[15, 15]">
                    <v-avatar color="#800080" size="40" tile>
                        <span class="white--text text-h5">親</span>
                    </v-avatar>
                </l-icon>
                <l-tooltip>
                    <h2>{{ p.name }}</h2>
                </l-tooltip>
            </l-marker>
            <l-polyline v-for="(p, idx) in trackingGateway" :key="'GW-Line-' + idx" :lat-lngs="p.polyline.latlngs" :color="p.poly_color" :weight="3" :opacity="0.9"></l-polyline>
            <!-- ================ -->
            <!-- ===== 中継機 ===== -->
            <l-marker v-for="(p, idx) in trackingDevice" :key="'REP-' + idx" :lat-lng="p.marker.latlng" :zIndexOffset="200" :visible="p.is_repeater == 1" @click="markerWasClicked(p)">
                <l-icon :icon-anchor="[12, 12]">
                    <v-avatar :color="triggerColor(p.triggered)" size="30" tile>
                        <span v-show="p.triggered == '3'" class="black--text text-h5">{{ p.triggered }}</span>
                        <span v-show="p.triggered == '2'" class="black--text text-h5">{{ p.triggered }}</span>
                        <span v-show="p.triggered == '1'" class="brack--text text-h5">{{ p.triggered }}</span>
                        <span v-show="p.triggered == 'T'" class="white--text text-h5">中</span>
                        <span v-show="p.triggered == 'A'" class="white--text text-h5">中</span>
                    </v-avatar>
                </l-icon>
                <l-tooltip>
                    <h2>{{ p.name }}</h2>
                    <h2>状態：{{ p.triggered }}</h2>
                    <h2>通信日時：{{ p.arrived_at }}</h2>
                    <h2>バッテリ：{{ p.battery_volt }} V</h2>
                    <h2>緯度：{{ p.gps_latitude }}</h2>
                    <h2>経度：{{ p.gps_longitude }}</h2>
                </l-tooltip>
            </l-marker>
            <l-polyline :visible="mapTraceLine" v-for="(p, idx) in trackingDevice" :key="'REP-Line-' + idx" :lat-lngs="p.polyline.latlngs" :color="p.poly_color" :weight="3" :opacity="0.9"></l-polyline>
            <div v-for="(p, idx) in trackingDevice" :key="'REP-MakerCircle-' + idx">
                <l-circle-marker :visible="mapTracePoint" v-for="(c, idx) in p.polyline.latlngs" :key="'REP-Circle-' + idx" :lat-lng="c" :color="p.poly_color" :fillColor="p.poly_color" :fillOpacity="1" :radius="2"></l-circle-marker>
            </div>
            <!-- ================ -->
            <!-- ===== 子機 ===== -->
            <l-marker v-for="(p, idx) in trackingDevice" :key="'DEV-' + idx" :lat-lng="p.marker.latlng" :zIndexOffset="300" :visible="p.is_repeater == 0" @click="markerWasClicked(p)">
                <l-icon :icon-anchor="[12, 12]">
                    <v-avatar :color="triggerColor(p.triggered)" size="20">
                        <span v-show="p.triggered == 'R'" class="white--text text-h5">{{ p.icon_no }}</span>
                        <span v-show="p.triggered == 'S'" class="white--text text-h5">{{ p.icon_no }}</span>
                        <span v-show="p.triggered == 'K'" class="brack--text text-h5">{{ p.icon_no }}</span>
                        <span v-show="p.triggered == 'T'" class="white--text text-h5">{{ p.icon_no }}</span>
                        <span v-show="p.triggered == 'A'" class="white--text text-h5">{{ p.icon_no }}</span>
                        <span v-show="p.triggered == 'C'" class="white--text text-h5">{{ p.icon_no }}</span>
                    </v-avatar>
                </l-icon>
                <l-tooltip>
                    <h2>{{ p.name }}</h2>
                    <h2>状態：{{ p.triggered }}</h2>
                    <h2>通信日時：{{ p.arrived_at }}</h2>
                    <h2>バッテリ：{{ p.battery_volt }} V</h2>
                    <h2>緯度：{{ p.gps_latitude }}</h2>
                    <h2>経度：{{ p.gps_longitude }}</h2>
                </l-tooltip>
            </l-marker>
            <l-polyline :visible="mapTraceLine" v-for="(p, idx) in trackingDevice" :key="'DEV-Line-' + idx" :lat-lngs="p.polyline.latlngs" :color="p.poly_color" :weight="3" :opacity="0.5"></l-polyline>
            <div v-for="(p, idx) in trackingDevice" :key="'DEV-MakerCircle-' + idx">
                <l-circle-marker :visible="mapTracePoint" v-for="(c, idx) in p.polyline.latlngs" :key="'DEV-Circle-' + idx" :lat-lng="c" :color="p.poly_color" :fillColor="p.poly_color" :fillOpacity="1" :radius="2"></l-circle-marker>
            </div>
            <!-- ========================================================= -->
            <v-navigation-drawer v-model="drawer" right absolute temporary style="z-index: 1001" color="grey lighten-3">
                <div class="ma-3">
                    <v-card>
                        <v-card-title class="blue darken-3 white--text">軌跡</v-card-title>
                        <v-card-text>
                            <v-switch v-model="mapTraceLine" label="軌跡(線)"></v-switch>
                            <v-switch v-model="mapTracePoint" label="軌跡(点)"></v-switch>
                        </v-card-text>
                    </v-card>
                    <div class="ma-3"></div>
                    <v-card>
                        <v-card-title class="blue darken-3 white--text">フィルター</v-card-title>
                        <v-card-text>
                            <div class="ma-3"></div>
                            <v-autocomplete v-model="selectDevice" :items="optDevices" outlined dense></v-autocomplete>
                            <v-btn color="success" block @click="markerRegist()">OK</v-btn>
                        </v-card-text>
                    </v-card>
                </div>
            </v-navigation-drawer>
        </l-map>
    </v-container>
</template>

<style>
.v-slider__track-container {
    height: 5px !important;
}
.v-slider__track {
    height: 30px;
}
/*--------
.v-slider__thumb {
    height: 20px;
    width: 20px;
}
------*/
</style>

<script>
import { LMap, LTileLayer, LControlLayers, LControlScale, LControl, LMarker, LTooltip, LPolyline, LCircleMarker, LIcon } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import L from "leaflet";
//delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    //markerColor: "orange",
});

export default {
    name: "Maps",
    components: {
        LMap,
        LTileLayer,
        LControlLayers,
        LControlScale,
        LControl,
        LMarker,
        LTooltip,
        LPolyline,
        LCircleMarker,
        LIcon,
    },
    //#########################################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbGateways: [],
            dbGatewayLogs: [],
            dbDevices: [],
            dbDeviceLogs: [],
            dispMonthDlg: false,
            targetDate: this.$moment().format("YYYY-MM-DD"),
            today: this.$moment().format("YYYY-MM-DD"),
            drawer: false,
            sliderVal: 1440,
            //---------------------
            optDevices: [
                { value: "001", text: "aaa" },
                { value: "003", text: "ccc" },
            ],
            selectDevice: "",
            //---------------------
            trackingGateway: [],
            trackingDevice: [
                // {
                //     device_id: 4000,
                //     name: "device-A",
                //     polyline: {
                //         latlngs: [
                //             [34.37771, 136.57586],
                //             [34.37774, 136.57265],
                //             [34.37899, 136.57319],
                //             [34.37951, 136.57161],
                //             [34.38026, 136.57024],
                //             [34.38151, 136.57264],
                //             [34.38082, 136.57508],
                //             [34.38132, 136.57694],
                //             [34.38294, 136.57645],
                //         ],
                //     },
                //     marker: {
                //         latlng: [34.38294, 136.57645],
                //     },
                // },
            ],
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: true,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    name: "淡色地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
                    options: {},
                },
                {
                    name: "衛星写真",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
                    options: {},
                },
                {
                    id: "t04",
                    name: "白地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/blank/{z}/{x}/{y}.png",
                    options: {
                        maxNativeZoom: 14,
                        maxZoom: 18,
                    },
                },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            //url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            center: {
                lat: 36.8444607407,
                lng: 138.273925,
            },
            zoom: 5,
            mapTraceLine: true,
            mapTracePoint: true,
            //-------------------------------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //-------------------------------------------
            modalDevice: {
                id: 0,
                name: "",
                jpgImg: "",
                date: "",
            },
            aspect: "16:9",
            isLoading: false,
            intID: undefined,
        };
    },
    //#########################################################################
    created: async function () {
        //---------------------------------
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------
        if (this.$localStorage.get("map.zoom")) this.zoom = Number(this.$localStorage.get("map.zoom"));
        if (this.$localStorage.get("map.lat")) this.center.lat = Number(this.$localStorage.get("map.lat"));
        if (this.$localStorage.get("map.lng")) this.center.lng = Number(this.$localStorage.get("map.lng"));
        if (this.$localStorage.get("map.layer")) {
            let layerName = this.$localStorage.get("map.layer");
            for (let layerObj of this.tileProviders) {
                if (layerObj.name == layerName) layerObj.visible = true;
                else layerObj.visible = false;
            }
        }
        //---------------------------------
        this.isLoading = true;
        await this.getSites();
        await this.getGateways();
        //await this.getGatewayLogs();
        await this.getDevices();
        //await this.getDeviceLogs();
        //await new Promise((r) => setTimeout(r, 3000));
        //this.markerRegist();
        this.reloadDeviceLogs();
        this.isLoading = false;
        //---------------------------------
        window.scrollTo(0, 0);
        this.autoLogout();
    },
    //#########################################################################
    watch: {},
    //#########################################################################
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //#########################################################################
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
        clearInterval(this.intID);
    },
    //#########################################################################
    methods: {
        markerWasClicked(p) {
            let txt = "";
            txt += p.name + "\n";
            txt += "状態:" + p.triggered + "\n";
            txt += "通信日時:" + this.targetDate + " " + p.arrived_at + "\n";
            txt += "バッテリ:" + p.battery_volt + " V\n";
            txt += "緯度:" + p.gps_latitude + "\n";
            txt += "経度:" + p.gps_longitude + "\n";
            navigator.clipboard.writeText(txt); // クリップボードにコピー
            // alert(txt);
        },
        //====================================================
        autoLogout() {
            const gThis = this;
            gThis.intID = setInterval(function () {
                //self.console.log("EXP =" + gThis.myInfo.exp);
                if (gThis.myInfo && gThis.myInfo.exp < gThis.$moment().unix()) {
                    gThis.$router.push({ name: "Login" });
                    return;
                }
            }, 1000 * 30);
        },
        //====================================================
        baselayerChanged: function (layer) {
            //self.console.log(layer.name);
            this.$localStorage.set("map.layer", layer.name);
        },
        zoomUpdated: function (zoom) {
            //self.console.log(zoom);
            this.$localStorage.set("map.zoom", zoom);
        },
        centerUpdated: function (center) {
            //self.console.log(center);
            this.$localStorage.set("map.lat", center.lat);
            this.$localStorage.set("map.lng", center.lng);
        },
        //====================================================
        mapCSS: function () {
            let h = this.windowHeight;
            h = h - 60;
            if (h < 300) h = 300;
            return "height: " + h + "px;";
        },
        //====================================================
        triggerColor(triggered) {
            let ret = "#0000aa";
            if (triggered == "R") ret = "#ff0000"; // レスキュー
            if (triggered == "S") ret = "#ff0000"; // SOS
            if (triggered == "K") ret = "#ffff00"; // コロコロセンサー
            if (triggered == "T") ret = "#0077ff"; // テスト
            if (triggered == "A") ret = "#0077ff"; // アライブ

            if (triggered == "3") ret = "#ff0000"; // 中継3
            if (triggered == "2") ret = "#ffff00"; // 中継2
            if (triggered == "1") ret = "#00ff00"; // 中継1
            return ret;
        },
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        async reloadDeviceLogs() {
            this.sliderVal = 1440;
            if (this.$moment(this.targetDate).format("YYYY-MM-DD") == this.$moment().format("YYYY-MM-DD")) {
                const hh = this.$moment().hours();
                const mm = this.$moment().minutes();
                let val = hh * 60 + mm;
                val = val + 15;
                val = Math.floor(val / 15) * 15;
                //self.console.log({ hh, mm, val });
                this.sliderVal = val;
            }
            this.dispMonthDlg = false;
            await this.getGatewayLogs();
            await this.getDeviceLogs();
            this.markerRegist();
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                // params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getGateways() {
            await this.axios({
                method: "GET",
                url: "/web/api/gateways",
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbGateways = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
                this.optDevices = [{ value: "", text: "全て表示" }];
                for (let item of this.dbDevices) {
                    let device_id = item.id;
                    let name = item.name;
                    this.optDevices.push({ value: device_id, text: name });
                }
            });
        },
        //====================================================
        async getGatewayLogs() {
            let stime = this.$moment(this.targetDate).format("YYYY-MM-DD");
            let etime = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            await this.axios({
                method: "GET",
                url: "/web/api/gateway_logs",
                params: {
                    //_fields: "id,device_id,gps_latitude,gps_longitude,arrived_at",
                    "arrived_at[EQGREAT]": stime,
                    "arrived_at[SMALL]": etime,
                    "_order[id]": "asc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbGatewayLogs = response.data.json;
            });
        },
        //====================================================
        async getDeviceLogs() {
            let stime = this.$moment(this.targetDate).format("YYYY-MM-DD");
            let etime = this.$moment(this.targetDate).add(1, "days").format("YYYY-MM-DD");
            await this.axios({
                method: "GET",
                url: "/web/api/device_logs",
                params: {
                    //_fields: "id,device_id,gps_latitude,gps_longitude,arrived_at",
                    "arrived_at[EQGREAT]": stime,
                    "arrived_at[SMALL]": etime,
                    "_order[id]": "asc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDeviceLogs = response.data.json;
            });
        },
        //====================================================
        markerRegist() {
            //self.console.log("slider val =", this.sliderVal);
            this.trackingDevice = [];
            this.trackingGateway = [];
            for (let item of this.dbDevices) {
                if (this.selectDevice && this.selectDevice != item["id"]) continue;
                this.trackingDevice.push({
                    pk: item["id"],
                    name: item["name"],
                    triggered: item["triggered"],
                    poly_color: item["poly_color"],
                    is_repeater: item["is_repeater"],
                    icon_no: item["icon_no"],
                    polyline: { latlngs: [] },
                    marker: { latlng: [0, 0] },
                    gps_latitude: 0,
                    gps_longitude: 0,
                });
            }
            for (let log of this.dbDeviceLogs) {
                const pk = log["device_id"];
                const lat = log["gps_latitude"];
                const lng = log["gps_longitude"];
                const arrived_at = log["arrived_at"];
                const triggered = log["triggered"];
                const battery_volt = log["battery_volt"];
                const hh = this.$moment(arrived_at).hours();
                const mm = this.$moment(arrived_at).minutes();
                for (let idx in this.trackingDevice) {
                    if (hh * 60 + mm > this.sliderVal) continue;
                    if (pk != this.trackingDevice[idx]["pk"]) continue;
                    if (lat == 0 && lng == 0) continue;
                    this.trackingDevice[idx].polyline.latlngs.push([lat, lng]);
                    this.trackingDevice[idx].marker.latlng = [lat, lng];
                    this.trackingDevice[idx].gps_latitude = lat;
                    this.trackingDevice[idx].gps_longitude = lng;
                    this.trackingDevice[idx].triggered = triggered;
                    this.trackingDevice[idx].arrived_at = this.$moment(arrived_at).format("HH:mm");
                    this.trackingDevice[idx].battery_volt = battery_volt;
                }
            }
            //----------------------------------------
            for (let item of this.dbGateways) {
                this.trackingGateway.push({
                    pk: item["id"],
                    name: item["name"],
                    triggered: "A",
                    poly_color: "#000000",
                    icon_no: "G",
                    polyline: { latlngs: [] },
                    marker: { latlng: [0, 0] },
                    gps_latitude: 0,
                    gps_longitude: 0,
                });
            }
            for (let log of this.dbGatewayLogs) {
                const pk = log["gateway_id"];
                const lat = log["gps_latitude"];
                const lng = log["gps_longitude"];
                const arrived_at = log["arrived_at"];
                const triggered = "A"; //アライブ
                const hh = this.$moment(arrived_at).hours();
                const mm = this.$moment(arrived_at).minutes();
                for (let idx in this.trackingGateway) {
                    if (hh * 60 + mm > this.sliderVal) continue;
                    if (pk != this.trackingGateway[idx]["pk"]) continue;
                    if (lat == 0 && lng == 0) continue;
                    this.trackingGateway[idx].polyline.latlngs.push([lat, lng]);
                    this.trackingGateway[idx].marker.latlng = [lat, lng];
                    this.trackingGateway[idx].gps_latitude = lat;
                    this.trackingGateway[idx].gps_longitude = lng;
                    this.trackingGateway[idx].triggered = triggered;
                }
            }
            //----------------------------------------
            this.drawer = false;
        },
        //====================================================
    },
    //#########################################################################
};
//=============================================
// https://day-journal.com/memo/leaflet-031/
//=============================================
</script>
