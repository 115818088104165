<template>
    <v-container fluid class="pa-0">
        <v-overlay :value="isLoading">
            <v-progress-circular :size="70" :width="7" indeterminate></v-progress-circular>
        </v-overlay>
        <!-- ========================================================= -->
        <h1 class="ma-3">デバイス 一覧</h1>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details class="mx-2"></v-text-field>
        <!-- ========================================================= -->
        <h2 class="text-center mt-5">子機</h2>
        <v-data-table hide-default-footer :headers="headerDevices" :items="dbDevices" mobile-breakpoint="0" :items-per-page="300" :search="search" class="elevation-5 ma-2">
            <template v-slot:[`item.triggered`]="{ item }">
                <div class="text-center">
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'R'" color="red"><span class="white--text">R</span></v-avatar>
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'S'" color="red"><span class="white--text">S</span></v-avatar>
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'K'" color="yellow"><span class="black--text">K</span></v-avatar>
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'T'" color="green"><span class="white--text">T</span></v-avatar>
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'A'" color="green"><span class="white--text">A</span></v-avatar>
                    <v-avatar size="30" v-show="item.is_repeater == 0 && item.triggered == 'C'" color="green"><span class="white--text">C</span></v-avatar>
                </div>
            </template>
            <template v-slot:[`item.battery`]="{ item }">
                <v-progress-linear :value="((item.battery - 3.4) / (4.1 - 3.4)) * 100" height="20" color="teal lighten-1">
                    <!-- 3.4V〜4.1V ゲージ -->
                    <strong>{{ item.battery }}</strong>
                </v-progress-linear>
            </template>
            <template v-slot:[`item.alived_at`]="{ item }">
                <div class="text-center" v-if="item.alived_at">
                    <a @click.stop="showDeviceLog(item.id, item.name)" class="font-weight-bold">{{ $moment(item.alived_at).format("MM-DD HH:mm") }}</a>
                </div>
            </template>
        </v-data-table>
        <!-- ========================================================= -->
        <h2 class="text-center mt-5">中継機</h2>
        <v-data-table hide-default-footer :headers="headerDevices" :items="dbRepeaters" mobile-breakpoint="0" :items-per-page="300" :search="search" class="elevation-5 ma-2">
            <template v-slot:[`item.triggered`]="{ item }">
                <div class="text-center">
                    <v-avatar tile size="30" v-show="item.is_repeater == 1 && item.triggered == '3'" color="red"><span class="white--text">3</span></v-avatar>
                    <v-avatar tile size="30" v-show="item.is_repeater == 1 && item.triggered == '2'" color="yellow"><span class="black--text">2</span></v-avatar>
                    <v-avatar tile size="30" v-show="item.is_repeater == 1 && item.triggered == '1'" color="green"><span class="black--text">1</span></v-avatar>
                    <v-avatar tile size="30" v-show="item.is_repeater == 1 && item.triggered == 'T'" color="green"><span class="white--text">T</span></v-avatar>
                    <v-avatar tile size="30" v-show="item.is_repeater == 1 && item.triggered == 'A'" color="green"><span class="white--text">A</span></v-avatar>
                </div>
            </template>
            <template v-slot:[`item.battery`]="{ item }">
                <v-progress-linear :value="((item.battery - 3.4) / (4.1 - 3.4)) * 100" height="20" color="teal lighten-1">
                    <!-- 3.4V〜4.1V ゲージ -->
                    <strong>{{ item.battery }}</strong>
                </v-progress-linear>
            </template>
            <template v-slot:[`item.alived_at`]="{ item }">
                <div class="text-center" v-if="item.alived_at">
                    <a @click.stop="showDeviceLog(item.id, item.name)" class="font-weight-bold">{{ $moment(item.alived_at).format("MM-DD HH:mm") }}</a>
                </div>
            </template>
        </v-data-table>
        <!-- ========================================================= -->
        <h2 class="text-center mt-5">親機</h2>
        <v-data-table hide-default-footer :headers="headerGateways" :items="dbGateways" mobile-breakpoint="0" :items-per-page="300" :search="search" class="elevation-5 ma-2">
            <template v-slot:[`item.battery`]="{ item }">
                <v-progress-linear :value="((item.battery - 11) / (13 - 11)) * 100" height="20" color="teal lighten-1">
                    <!-- 11.0V〜13.0V ゲージ -->
                    <strong>{{ item.battery }}</strong>
                </v-progress-linear>
            </template>
            <template v-slot:[`item.alived_at`]="{ item }">
                <div class="text-center" v-if="item.alived_at">
                    <a @click.stop="showGatewayLog(item.id, item.name)" class="font-weight-bold">{{ $moment(item.alived_at).format("MM-DD HH:mm") }}</a>
                </div>
            </template>
        </v-data-table>
        <!-- ========================================================= -->
        <v-dialog v-model="dlgDeviceOpen" fullscreen>
            <v-card>
                <v-toolbar dark color="primary">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ dlg.device_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dlgDeviceOpen = false"> <v-icon>mdi-close</v-icon> </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-data-table :height="windowHeight - 200" dense fixed-header :headers="headerDeviceLogs" :items="dbDeviceLogs" mobile-breakpoint="0" :items-per-page="itemsPerPage" class="ma-3 elevation-5" :footer-props="footerProps"></v-data-table>
                <div class="text-right">
                    <v-btn @click="downloadDeviceLogsCSV()" color="success" class="ma-3">CSV</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- ========================================================= -->
        <v-dialog v-model="dlgGatewayOpen" fullscreen>
            <v-card>
                <v-toolbar dark color="primary">
                    <v-spacer></v-spacer>
                    <v-toolbar-title>{{ dlg.gateway_name }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn icon dark @click="dlgGatewayOpen = false"> <v-icon>mdi-close</v-icon> </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-data-table :height="windowHeight - 200" dense fixed-header :headers="headerGatewayLogs" :items="dbGatewayLogs" mobile-breakpoint="0" :items-per-page="itemsPerPage" class="ma-3 elevation-5" :footer-props="footerProps"></v-data-table>
                <div class="text-right">
                    <v-btn @click="downloadGatewayLogsCSV()" color="success" class="ma-3">CSV</v-btn>
                </div>
            </v-card>
        </v-dialog>
        <!-- ========================================================= -->
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
        <div class="ma-5 pa-5"></div>
    </v-container>
</template>

<style>
/*
.v-data-table-header tr th {
    color: #fff;
    background: #41a2e6;
    border-spacing: 1;
}
.v-data-table td {
    background: #f0f8ff;
}
*/
</style>

<script>
export default {
    name: "DeviceList",
    components: {},
    //#########################################################################
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSites: [],
            dbDevices: [],
            dbDeviceLogs: [],
            dbRepeaters: [],
            dbRepeaterLogs: [],
            dbGateways: [],
            dbGatewayLogs: [],
            headerDevices: [
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: true, text: "デバイス名", value: "name" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: false, text: "状態", value: "triggered" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: false, text: "バッテリ", value: "battery" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: false, text: "最終通信", value: "alived_at" },
            ],
            headerGateways: [
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: true, text: "デバイス名", value: "name" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: false, text: "バッテリ", value: "battery" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", filterable: false, text: "最終通信", value: "alived_at" },
            ],
            headerDeviceLogs: [
                //{ divider: true, class: "blue-grey darken-2 white--text", align: "center", text: "id", value: "id" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 170, text: "通信日時", value: "arrived_at" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "子機LoRa", value: "lora_serial" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 100, text: "イベント", value: "triggered" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 130, text: "バッテリ電圧", value: "battery_volt" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "親機LoRa", value: "gw_lora_serial" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 130, text: "無線電波強度", value: "device_rssi" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "環境ノイズ", value: "ra_rssi" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "GPS緯度", value: "gps_latitude" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "GPS経度", value: "gps_longitude" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 150, text: "タイムコード", value: "time_code" },
            ],
            headerGatewayLogs: [
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 170, text: "通信日時", value: "arrived_at" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "親機LoRa", value: "lora_serial" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 130, text: "バッテリ電圧", value: "battery" },
                //{ divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 130, text: "無線電波強度", value: "device_rssi" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 130, text: "LTE電波強度", value: "lte_rssi" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "GPS緯度", value: "gps_latitude" },
                { divider: true, class: "blue-grey darken-2 white--text", align: "center", width: 120, text: "GPS経度", value: "gps_longitude" },
            ],
            search: "",
            dlgDeviceOpen: false,
            dlgGatewayOpen: false,
            dlg: {
                device_id: 0,
                device_name: "",
                device_logs: [],
            },
            itemsPerPage: 20,
            footerProps: {
                itemsPerPageOptions: [10, 20, 30, 50, 100, -1],
            },
            //------------------------------------------
            windowWidth: window.innerWidth, // 画面サイズ
            windowHeight: window.innerHeight, //画面サイズ
            //------------------------------------------
            isLoading: false,
            intID: undefined,
        };
    },
    //#########################################################################
    created: async function () {
        this.jwt = this.$localStorage.get("jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Login" });
            return;
        }
        this.myInfo = this.$jwt.decode(this.jwt);
        if (this.myInfo && this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Login" });
            return;
        }
        //---------------------------------
        this.isLoading = true;
        await this.getSites();
        await this.getDevices();
        await this.getRepeaters();
        await this.getGateways();
        //await new Promise((r) => setTimeout(r, 3000));
        this.isLoading = false;
        //---------------------------------
        window.scrollTo(0, 0);
        this.autoLogout();
    },
    //#########################################################################
    mounted: function () {
        window.addEventListener("resize", this.handleResize);
    },
    //#########################################################################
    beforeDestroy: function () {
        window.removeEventListener("resize", this.handleResize);
        clearInterval(this.intID);
    },
    //#########################################################################
    methods: {
        //====================================================
        handleResize: function () {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        //====================================================
        autoLogout() {
            const gThis = this;
            gThis.intID = setInterval(function () {
                // self.console.log("EXP =" + gThis.myInfo.exp);
                if (gThis.myInfo && gThis.myInfo.exp < gThis.$moment().unix()) {
                    gThis.$router.push({ name: "Login" });
                    return;
                }
            }, 1000 * 30);
        },
        //====================================================
        async getSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                // params: { "_order[sort_no]": "desc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbSites = response.data.json;
            });
        },
        //====================================================
        async getDevices() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { is_repeater: "0", "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDevices = response.data.json;
            });
        },
        //====================================================
        async getRepeaters() {
            await this.axios({
                method: "GET",
                url: "/web/api/devices",
                params: { is_repeater: "1", "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbRepeaters = response.data.json;
            });
        },
        //====================================================
        async getGateways() {
            await this.axios({
                method: "GET",
                url: "/web/api/gateways",
                params: { "_order[name]": "asc" },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbGateways = response.data.json;
            });
        },
        //====================================================
        async getDeviceLogs(device_id) {
            await this.axios({
                method: "GET",
                url: "/web/api/device_logs",
                params: {
                    device_id: device_id,
                    _limit: 10000,
                    "_order[id]": "desc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbDeviceLogs = response.data.json;
                //--------------------------
                // 日付フォーマットを小細工
                for (let i in this.dbDeviceLogs) {
                    let dt = this.dbDeviceLogs[i].arrived_at;
                    if (dt) {
                        let dt_format = this.$moment(dt).format("YYYY-MM-DD HH:mm:ss");
                        this.dbDeviceLogs[i].arrived_at = dt_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        async getGatewayLogs(gateway_id) {
            await this.axios({
                method: "GET",
                url: "/web/api/gateway_logs",
                params: {
                    gateway_id: gateway_id,
                    _limit: 10000,
                    "_order[id]": "desc",
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000,
            }).then((response) => {
                this.dbGatewayLogs = response.data.json;
                //--------------------------
                // 日付フォーマットを小細工
                for (let i in this.dbGatewayLogs) {
                    let dt = this.dbGatewayLogs[i].arrived_at;
                    if (dt) {
                        let dt_format = this.$moment(dt).format("YYYY-MM-DD HH:mm:ss");
                        this.dbGatewayLogs[i].arrived_at = dt_format;
                    }
                }
                //--------------------------
            });
        },
        //====================================================
        // ダイアログ（デバイスログ 300件）
        //====================================================
        showDeviceLog(device_id, device_name) {
            this.isLoading = true;
            this.getDeviceLogs(device_id);
            //---------------------------
            this.isLoading = false;
            this.dlg.device_name = device_name;
            this.dlgDeviceOpen = true;
        },
        //====================================================
        // ダイアログ（ゲートウェイログ 300件）
        //====================================================
        showGatewayLog(gateway_id, gateway_name) {
            this.isLoading = true;
            this.getGatewayLogs(gateway_id);
            //---------------------------
            this.isLoading = false;
            this.dlg.gateway_name = gateway_name;
            this.dlgGatewayOpen = true;
        },
        //====================================================
        // CSV ダウンロード
        //====================================================
        downloadDeviceLogsCSV() {
            //----------------------------------------------------------------
            let mergeAry = [];
            mergeAry.push(["通信日時", "子機LoRa", "イベント", "バッテリ電圧", "親機LoRa", "無線電波強度", "環境ノイズ", "GPS緯度", "GPS経度", "タイムコード", "data"]);
            //----------------------------------------------------------------
            for (let obj of this.dbDeviceLogs) {
                mergeAry.push([obj.arrived_at, obj.lora_serial, obj.triggered, obj.battery_volt, obj.gw_lora_serial, obj.device_rssi, obj.ra_rssi, obj.gps_latitude, obj.gps_longitude, obj.time_code, obj.raw_data]);
            }
            //----------------------------------------------------------------
            //  配列をCSV化
            //----------------------------------------------------------------
            let csv = "\ufeff" + "\n";
            for (let i in mergeAry) {
                csv += mergeAry[i].join(",") + "\n";
            }
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "tasucall-dev-logs.csv";
            document.body.appendChild(link);
            link.click();
        },
        //====================================================
        downloadGatewayLogsCSV() {
            //----------------------------------------------------------------
            let mergeAry = [];
            mergeAry.push(["通信日時", "親機LoRa", "バッテリ電圧", "LTE電波強度", "GPS緯度", "GPS経度"]);
            //----------------------------------------------------------------
            for (let obj of this.dbGatewayLogs) {
                mergeAry.push([obj.arrived_at, obj.lora_serial, obj.battery, obj.lte_rssi, obj.gps_latitude, obj.gps_longitude]);
            }
            //----------------------------------------------------------------
            //  配列をCSV化
            //----------------------------------------------------------------
            let csv = "\ufeff" + "\n";
            for (let i in mergeAry) {
                csv += mergeAry[i].join(",") + "\n";
            }
            let blob = new Blob([csv], { type: "text/csv" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "tasucall-gw-logs.csv";
            document.body.appendChild(link);
            link.click();
        },
        //====================================================
    },
    //#########################################################################
};
</script>
